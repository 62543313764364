import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import App from "./App.vue";
import router from "./router";
// import 'lib-flexible'
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import qs from "qs";
import "./assets/css/responsive.css";
import BaiduMap from "vue-baidu-map";
import AMapLoader from "@amap/amap-jsapi-loader";
import {
    getToken,
    intervalRefreshToken,
    setTimeToken,
    getTimeToken,
} from "./utils/auth.js";
import { flexible } from "./utils/flexible.js";
import websocket from "./utils/webSocket";
// 导入qiankun.js
import { registerMicroApps, setDefaultMountApp, start } from "qiankun";
import moment from "moment";
// 自定义组件-文本超出隐藏
import OverflowTxt from "./components/overflowTxt.vue";
import EventBus from "./utils/EventBus.js";

// 全局注册组件
Vue.component("overflow-txt", OverflowTxt);

var _versions = store.state.version;

const upmsDomain = process.env.VUE_APP_UPMS_DOMAIN;

// 注册子应用
registerMicroApps([
    {
        name: "vue app", // 子应用名称
        // entry: '//localhost:8081',  // 子应用入口
        entry: upmsDomain, // 子应用入口
        container: "#sub", // 子应用所在容器
        activeRule: "/vue", // 子应用触发规则（路径）
    },
]);

// 启动默认应用
// setDefaultMountApp('/vue')

// 开启服务
start({
    prefetch: true, // 取消预加载
});

// console.log(_versions)
var baseURL = store.getters.baseURL,
    baseURL2 = "";
if (_versions == "manager") {
    baseURL2 = "http://39.97.102.165:8807";
}

Vue.prototype.$websocket = websocket;
Vue.prototype._versions = _versions;
Vue.prototype.role = "";
Vue.prototype.userInfo = {};
Vue.prototype.$axios = axios;
// Vue.prototype.$axios.defaults.baseURL=baseURL
// Vue.prototype.$axios.defaults.headers.common['Authorization'] = getToken()||'AUTH_TOKEN';
// Vue.prototype.$axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// console.log(process.env.NODE_ENV);
// console.log(baseURL)
Vue.prototype.instance = Vue.prototype.$axios.create({
    baseURL,
    // timeout:1000
});
Vue.prototype.instance2 = Vue.prototype.$axios.create({
    baseURL: baseURL2,
    // timeout:1000
});
//请求拦截器
Vue.prototype.instance.interceptors.request.use(
    (config) => {
        config.headers["X-From-Business-Line"] = "ubi";
        localStorage.setItem("X-From-Business-Line", "ubi");
        //请求之前的操作
        // config.headers.token=getToken();

        config.headers.Authorization = "Bearer " + getToken();
        var userInfo = localStorage.getItem("userInfo");
        if (userInfo) {
            userInfo = JSON.parse(userInfo);
            if (config.method.toLowerCase() == "get") {
                if (config.params) {
                    config.params.batchId = userInfo.batchId;
                    // config.params.companyId=userInfo.company;
                    config.params.userId = userInfo.id;
                }
            } else {
                if (config.data) {
                    config.data.batchId = userInfo.batchId;
                    // config.data.companyId=userInfo.company;
                    config.data.userId = userInfo.id;
                }
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
//响应拦截器
var index = 1;
Vue.prototype.instance.interceptors.response.use(
    (res) => {
        if (res.data.code == 401) {
            localStorage.setItem("tokenError", 401);
            index == 1 &&
                window.alert("抱歉，当前账号在其他设备登录，已强制退出");
            index++;
            localStorage.clear();
            location.href = "/";
        }
        return res;
    },
    (error) => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    index == 1 &&
                        window.alert(
                            "抱歉，当前账号在其他设备登录，已强制退出"
                        );
                    index++;
                    localStorage.clear();
                    router.replace({
                        path: "/",
                        // query: {redirect: router.currentRoute.fullPath}//登录成功后跳入浏览的当前页面
                    });
                    // location.reload();
                    break;
            }
        }
    }
);
Vue.config.productionTip = false;
//定时刷新token
// intervalRefreshToken();
Vue.use(ElementUI, VueAxios, axios);
Vue.use(BaiduMap, {
    ak: "DHewLYOSvq4s0TFIaFCagCNWhvmHWzLB",
});
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
var fontSize = 0;
var fontRem = 0;
// 获取 URL 参数
Vue.prototype.getQueryString = function (name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var href = window.location.href;
    href = decodeURI(href);
    var r = href.substr(href.indexOf("?") + 1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
};

var _this = new Vue();
Vue.prototype.$getWebsocketToken = async () => {
    let returnToken;
    await _this.instance
        .get("/ubi/api/websocket/token", { params: {} })
        .then((res) => {
            let data = res.data;
            if (data.code == 200 && data.data) {
                EventBus.$emit("sendMsg", { websocketToken: data.data });
            }
        })
        .catch((err) => {
            console.log("token获取失败:");
            console.log(err);

            setTimeout(() => {
                _this.$getWebsocketToken();
            }, 10000);
        });

    return returnToken;
};

EventBus.$on("sendMsg", (data) => {
    console.log("接收到 sendMsg");
    console.log(data);
    if (data.getFlag) {
        _this.$getWebsocketToken();
    }
});

// changeFrameHeight();
//设置字体自适应  默认1920*1080 为100px
function changeFrameHeight(ratio) {
    (function (doc, win) {
        var docEl = doc.documentElement,
            resizeEvt =
                "orientationchange" in window ? "orientationchange" : "resize",
            recalc = function () {
                var clientWidth = docEl.clientWidth;
                // var devicePixelRatio=window.devicePixelRatio||1;
                //             if (!clientWidth) return;
                //             docEl.style.fontSize = 100/6 * (clientWidth / 320) + 'px';
                //             fontRem=parseFloat(document.documentElement.style.fontSize);
                //             fontSize=100/6 * (clientWidth / 320);
                var clientHeight = docEl.clientHeight;
                if (!clientHeight) return;
                docEl.style.fontSize = (100 / 6) * (clientHeight / 180) + "px";
                fontRem = parseFloat(document.documentElement.style.fontSize);
                fontSize = (100 / 6) * (clientWidth / 320);
            };
        if (!doc.addEventListener) return;
        win.addEventListener(resizeEvt, recalc, false);
        doc.addEventListener("DOMContentLoaded", recalc, false);
    })(document, window);
}
Vue.prototype.moment = moment;
// window.addEventListener('resize', function() {
//       changeFrameHeight();
// })
// 时间格式化
Vue.prototype.dateFormatting = function (date, fmt) {
    var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
    for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
                RegExp.$1,
                RegExp.$1.length == 1
                    ? o[k]
                    : ("00" + o[k]).substr(("" + o[k]).length)
            );
    return fmt;
};
//选择月份 前num个月的 不含当月
Vue.prototype.selectMonth = function (num, sign) {
    var nowDate = new Date();
    var year = nowDate.getFullYear();
    var month = nowDate.getMonth() + 1; //0-11表示1-12月
    var startMonth = "",
        endMonth = "";
    if (month - num <= 0) {
        startMonth =
            year - 1 + "-" + (12 - (num - month)).toString().padStart(2, "0");
    } else {
        startMonth = year + "-" + (month - num).toString().padStart(2, "0");
    }
    let _num = sign == "defalut" ? 0 : 1;
    if (month - 1 == 0) {
        endMonth =
            year - 1 + "-" + (12 - (_num - month)).toString().padStart(2, "0");
    } else {
        endMonth = year + "-" + (month - _num).toString().padStart(2, "0");
    }
    this.navForm.startTime = startMonth;
    this.navForm.endTime = endMonth;
};
//计算月份间隔  开始月份-结束月份
(Vue.prototype.calcMonthInterval = function (startTime, endTime) {
    let startYear = startTime.split("-")[0];
    let startMonth = parseInt(startTime.split("-")[1]);
    let endYear = endTime.split("-")[0];
    let endMonth = parseInt(endTime.split("-")[1]);
    if (endYear == startYear) {
        return endMonth - startMonth + 1;
    } else {
        let monthNum = 0;
        monthNum = (endYear - startYear) * 12 - startMonth + endMonth + 1;
        return monthNum;
    }
}),
    (Array.prototype.indexOf = function (val) {
        for (var i = 0; i < this.length; i++) {
            if (this[i] == val) return i;
        }
        return -1;
    });
Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
        this.splice(index, 1);
    }
};
Vue.prototype.BMaptoAMap = function (lng, lat) {
    const X_PI = (Math.PI * 3000.0) / 180.0;
    let x = lng - 0.0065;
    let y = lat - 0.006;
    let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * X_PI);
    let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * X_PI);
    let amap_lng = z * Math.cos(theta);
    let amap_lat = z * Math.sin(theta);
    return {
        lng: amap_lng,
        lat: amap_lat,
    };
};
Vue.prototype.transformFromWGSToGCJ = function (latitude, longitude) {
    var lat = "";
    var lon = "";
    var ee = 0.00669342162296594323;
    var a = 6378245.0;
    var pi = 3.14159265358979324;
    var adjustLat = transformLatWithXY(longitude - 105.0, latitude - 35.0);
    var adjustLon = transformLonWithXY(longitude - 105.0, latitude - 35.0);
    var radLat = (latitude / 180.0) * pi;
    var magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    var sqrtMagic = Math.sqrt(magic);
    adjustLat =
        (adjustLat * 180.0) / (((a * (1 - ee)) / (magic * sqrtMagic)) * pi);
    adjustLon = (adjustLon * 180.0) / ((a / sqrtMagic) * Math.cos(radLat) * pi);
    latitude = latitude + adjustLat;
    longitude = longitude + adjustLon;
    return { lat: latitude, lng: longitude };
};
function transformLatWithXY(x, y) {
    var pi = 3.14159265358979324;
    var lat =
        -100.0 +
        2.0 * x +
        3.0 * y +
        0.2 * y * y +
        0.1 * x * y +
        0.2 * Math.sqrt(Math.abs(x));
    lat +=
        ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) *
            2.0) /
        3.0;
    lat +=
        ((20.0 * Math.sin(y * pi) + 40.0 * Math.sin((y / 3.0) * pi)) * 2.0) /
        3.0;
    lat +=
        ((160.0 * Math.sin((y / 12.0) * pi) + 320 * Math.sin((y * pi) / 30.0)) *
            2.0) /
        3.0;
    return lat;
}

function transformLonWithXY(x, y) {
    var pi = 3.14159265358979324;
    var lon =
        300.0 +
        x +
        2.0 * y +
        0.1 * x * x +
        0.1 * x * y +
        0.1 * Math.sqrt(Math.abs(x));
    lon +=
        ((20.0 * Math.sin(6.0 * x * pi) + 20.0 * Math.sin(2.0 * x * pi)) *
            2.0) /
        3.0;
    lon +=
        ((20.0 * Math.sin(x * pi) + 40.0 * Math.sin((x / 3.0) * pi)) * 2.0) /
        3.0;
    lon +=
        ((150.0 * Math.sin((x / 12.0) * pi) +
            300.0 * Math.sin((x / 30.0) * pi)) *
            2.0) /
        3.0;
    return lon;
}
