<template>
    <div class="wrap">
        <phone-code-login v-if="switchLoginFlag == 1"></phone-code-login>
        <number-code-login v-else></number-code-login>
    </div>
</template>
<script>
import { setTimeToken } from '../utils/auth.js';
import NumberCodeLogin from '@/components/numberCodeLogin.vue';
import PhoneCodeLogin from '@/components/phoneCodeLogin.vue';
export default {
    name: 'Login',
    components: {
        NumberCodeLogin,
        PhoneCodeLogin
    },
    data() {
        return {
            switchLoginFlag: 1 // 1 手机号  2 数字
        };
    },
    mounted() {},
    methods: {}
};
</script>
<style scoped>
* {
    user-select: none;
}
.login_box .submit_btn {
    width: 100%;
    background: #22bdbd;
    border-color: #22bdbd;
    color: #fff;
}
.login_box .el-button:focus,
.login_box .el-button:hover,
.code_btn:hover {
    background: #81eff0;
    border-color: #81eff0;
    color: #4c6161;
}
.verify-box {
    margin-left: 0.2rem;
    width: 86px;
}
.code_btn {
    width: 125px;
    margin-left: 15px;
    background: #22bdbd;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    border: none;
}
.code_btn.active {
    color: #aaa;
    background: #eeeeee;
}
.qrcode_input {
    width: 190px;
}
.qrcode /deep/.el-form-item__content {
    display: flex;
    width: 100%;
}
.wrap {
    height: 100%;
    width: 100%;
    position: relative;
    background-image: url(../../public/images/msi/login_bg.png);
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: cover;
    background-position: center;
}
.version {
    position: absolute;
    bottom: 0.5rem;
    right: 20%;
    z-index: 50;
    color: #858688;
}
.login_box {
    /* width:4.2rem;
	    height:5.9rem; */
    width: 350px;
    /* height:320px; */
    position: absolute;
    top: 50%;
    right: 12%;
    transform: translate(-12%, -50%);
    z-index: 50;
    background: url(../../public/images/msi/login_form.png) no-repeat;
    background-size: 100% 100%;
    border-radius: 0.1rem;
    padding: 30px;
    /* color: #fff; */
}
.login_box .el-form-item__label {
    color: #fff !important;
}
.login_box .el-input__inner {
    background-color: rgba(255, 255, 255, 0.1);
    /* color: #fff; */
}
.login_title {
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
    margin-bottom: 40px;
    text-align: center;
}
.login_form {
    width: 100%;
    margin: 0 auto 0;
}
.login_form .el-input__inner {
    color: #cfdde5;
}
.login_btn button {
    border: none;
    background: #003b8f;
}
.login_btn button:hover {
    background: #01265a;
}
.user,
.password {
    width: 100%;
    /* height:0.48rem; */
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-bottom: 1px solid #dfe5ec;
    padding: 0 0.16rem;
}
.password {
    justify-content: space-between;
}
.user span,
.password span {
    width: 0.14rem;
}
.password_box {
    display: flex;
    align-items: center;
}

.isshowpass {
    cursor: pointer;
    width: 0.22rem;
}
.login_btn {
    margin-top: 30px;
}
.login_btn button {
    width: 100%;
}
.resetPassBtn {
    color: #409eff;
    cursor: pointer;
}
</style>