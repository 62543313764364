<template>
    <div class="wrap">
        <div class="login_box">
            <p class="login_title">登录</p>
            <el-form class="login_form" @keyup.enter.native="handleLogin" ref="form" :model="form">
                <el-form-item>
                    <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="form.password" placeholder="请输入密码" type="password"></el-input>
                </el-form-item>
                <el-form-item class="qrcode" v-if="isVerification">
                    <el-input class="qrcode_input" v-model="form.code" placeholder="请输入验证码"></el-input>
                    <div v-if="qrcodeSrc" class="verify-box" @click="refreshCode">
                        <img :src="'data:image/jpeg;base64,' + qrcodeSrc.img" alt="" />
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button class="submit_btn" :disabled="isDisabled" @click="handleLogin">登 录</el-button>
                </el-form-item>
                <span class="resetPassBtn" @click="resetPasswordDialogOpen">重置密码</span>
            </el-form>
        </div>
        <div class="version">Copyright @{{ new Date().getFullYear() }}</div>
        <div>
            <el-dialog :visible.sync="dialogVisible" title="重置密码" width="680px" append-to-body>
                <el-form ref="resetPasswordForm" :model="resetPasswordForm" :rules="resetPasswordRules" label-width="120px">
                    <el-form-item label="用户名" prop="username">
                        <el-input v-model="resetPasswordForm.username" placeholder="请输入用户名"></el-input>
                    </el-form-item>
                    <el-form-item label="图片验证码" prop="code">
                        <el-row>
                            <el-col :span="16">
                                <el-input v-model="resetPasswordForm.code" placeholder="请输入图片验证码"></el-input>
                            </el-col>
                            <el-col :span="8">
                                <div v-if="qrcodeSrc" class="verify-box" @click="refreshRestCaptcha">
                                    <img :src="'data:image/jpeg;base64,' + resetPasswordForm.captcha.img" />
                                </div>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="短信验证码" prop="mobileCode">
                        <el-row>
                            <el-col :span="16">
                                <el-input v-model="resetPasswordForm.mobileCode" placeholder="请输入短信验证码"></el-input>
                            </el-col>
                            <el-col :span="8">
                                <el-button :disabled="countdown > 0" @click="getSmsCode">
                                    {{ countdown > 0 ? `${countdown}秒后重试` : '获取短信验证码' }}
                                </el-button>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="新密码" prop="password">
                        <el-input type="password" v-model="resetPasswordForm.password" placeholder="请输入新密码"></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="confirmPassword">
                        <el-input type="password" v-model="resetPasswordForm.confirmPassword" placeholder="请输入确认密码"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer">
                    <el-button @click="cancelResetPwd()">取消</el-button>
                    <el-button type="primary" @click="resetPassword">确定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { setTimeToken } from '../utils/auth.js';
export default {
    name: 'Login',
    data() {
        return {
            codeTime: 0, //验证码时间
            redirect: '',
            isVerification: true,
            isDisabled: false,
            identifyCode: '',
            identifyCodes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0],
            passwordShow: false, //密码是否显示
            codeActive: false, //发送验证码按钮颜色
            isClickCode: false, //是否点击过发送验证码
            form: {
                code: '',
                username: '', //用户名
                password: '' //密码
            },
            dialogVisible: false,
            countdown: 0,
            qrcodeSrc: '',
            timer: null, // 定时器变量
            resetPasswordForm: {
                username: '',
                code: '',
                mobileCode: '',
                password: '',
                confirmPassword: '',
                uuid: '',
                captcha: ''
            },
            resetPasswordRules: {
                username: [
                    { required: true, message: '用户名不能为空', trigger: 'blur' },
                    { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
                ],
                code: [{ required: true, message: '图片验证码不能为空', trigger: 'blur' }],
                mobileCode: [{ required: true, message: '短信验证码不能为空', trigger: 'blur' }],
                password: [
                    { required: true, message: '密码不能为空', trigger: 'blur' },
                    { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
                ],
                confirmPassword: [
                    { required: true, message: '确认密码不能为空', trigger: 'blur' },
                    { validator: this.validateConfirmPassword, trigger: 'blur' }
                ]
            }
        };
    },
    mounted() {
        this.getCaptcha();
    },
    methods: {
        // 获取图形验证码 数字验证码
        getCaptcha() {
            this.instance
                .get('/upms/api/captcha/generate', {})
                .then((res) => {
                    var data = res.data;
                    if (data.code === 200) {
                        this.qrcodeSrc = data.data;
                    }
                })
                .catch((res) => {});
        },
        refreshCode() {
            // 数字验证码
            this.qrcodeSrc = '';
            this.getCaptcha();
        },
        //发送验证码
        sendCode() {
            var _this = this;
            if (!this.form.username) {
                _this.$message.error('抱歉，请输入用户名');
                return false;
            }
            if (!_this.form.password) {
                _this.$message.error('抱歉,请输入密码!');
                return false;
            }
            if (this.codeTime <= 0) {
                _this.codeTime = 60;
                this.isClickCode = true;
                this.instance.post('/upms/api/user/loginSms', this.form).then((res) => {
                    if (res.data.code == 200) {
                        var timer = setInterval(function () {
                            _this.codeTime--;
                            if (_this.codeTime <= 0) {
                                _this.isClickCode = false;
                                _this.codeActive = true;
                                clearInterval(timer);
                            }
                        }, 1000);
                    } else {
                        _this.codeTime = 0;
                        _this.codeActive = false;
                        _this.isClickCode = false;
                        _this.$message.error(res.data.message);
                    }
                });
            }
        },
        // 图形验证码
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min);
        },
        makeCode(o, l) {
            for (let i = 0; i < l; i++) {
                this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
            }
        },
        //点击提交按钮的方法
        handleLogin() {
            var _this = this;
            if (!_this.form.username) {
                _this.$message.error('抱歉,请输入用户名!');
                return false;
            }
            if (!_this.form.password) {
                _this.$message.error('抱歉,请输入密码!');
                return false;
            }
            if (!_this.form.code) {
                _this.$message.error('抱歉,请输入验证码!');
                return false;
            }
            this.isDisabled = true;
            this.form.uuid = this.qrcodeSrc.uuid;
            this.instance
                .post('/upms/api/user/login', this.form)
                .then((res) => {
                    var data = res.data;
                    this.isDisabled = false;
                    if (data.code == 200) {
                        localStorage.setItem('AUTH_TOKEN', data.data.accessToken);
                        localStorage.setItem('username', data.data.username);
                        localStorage.setItem('userInfo', JSON.stringify(data.data));
                        this.userMenuTree();
                    } else {
                        this.getCaptcha();
                        this.form.code = null;
                        this.$message.error(data.message);
                    }
                })
                .catch((res) => {
                    this.isDisabled = false;
                });
        },
        getRestCaptcha() {
            this.instance
                .get('/upms/api/captcha/generate', {})
                .then((res) => {
                    var data = res.data;
                    if (data.code === 200) {
                        this.qrcodeSrc = data.data;
                        this.resetPasswordForm.captcha = data.data;
                    }
                })
                .catch((res) => {});
        },
        refreshRestCaptcha() {
            this.resetPasswordForm.captcha = '';
            this.getRestCaptcha();
        },
        resetPasswordDialogOpen() {
            this.resetPasswordFormClear();
            this.dialogVisible = true;
            this.resetPasswordForm.captcha = '';
            this.getRestCaptcha();
        },
        cancelResetPwd() {
            // this.resetPasswordFormClear()
            this.dialogVisible = false;
            this.resetPasswordForm = {
                username: '',
                code: '',
                mobileCode: '',
                password: '',
                confirmPassword: '',
                uuid: '',
                captcha: ''
            };
        },
        resetPasswordFormClear() {
            this.resetPasswordForm = JSON.parse(JSON.stringify(this.resetPasswordForm));
        },
        getSmsCode() {
            if (this.resetPasswordForm.code === '') {
                this.$message.error('请输入图片验证码');
                return;
            }
            this.countdown = 60;
            setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                } else {
                    clearInterval(this.timer);
                    this.timer = null;
                }
            }, 1000);
            this.resetPasswordForm.uuid = this.resetPasswordForm.captcha.uuid;
            let param = JSON.parse(JSON.stringify(this.resetPasswordForm));
            this.instance
                .post('/upms/api/user/resetPasswordSms', param)
                .then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        this.$message.success('发送短信验证码成功');
                    } else {
                        this.$message.error(data.message);
                    }
                })
                .catch((res) => {
                    console.log(res);
                    this.$message.error('发送短信验证码失败! 稍后重试');
                });
        },
        resetPassword() {
            this.$refs.resetPasswordForm.validate((vaild) => {
                if (vaild) {
                    this.instance
                        .post('/upms/api/user/resetPassword', this.resetPasswordForm)
                        .then((res) => {
                            let data = res.data;
                            if (data.code === 200) {
                                this.dialogVisible = false;
                                this.$message.success('重置密码成功');
                            } else {
                                this.$message.error(data.message);
                            }
                        })
                        .catch((res) => {
                            console.log(res);
                            this.$message.error('重置密码失败! 稍后重试');
                        });
                }
            });
        },
        userMenuTree() {
            this.instance
                .get('/upms/api/menu/getMenuTree', this.form)
                .then((res) => {
                    let data = res.data;
                    let _path = '/vehicle/vehicleData';
                    if (data.code == 200) {
                        localStorage.setItem('userMenu', JSON.stringify(res.data.data));

                        let menus = data.data;
                        let tmpMenuId = null;

                        if (localStorage.getItem('redirectPath')) {
                            _path = localStorage.getItem('redirectPath');
                            localStorage.removeItem('redirectPath');
                        } else if (menus[0]) {
                            tmpMenuId = menus[0].menuId ? menus[0].menuId : null;
                            if (!menus[0].children) {
                                if (menus[0].path == '/Home') {
                                    _path = menus[0].path;
                                } else {
                                    _path = menus[0].path + '/index';
                                }
                            } else {
                                _path = menus[0].path + menus[0].children[0].path;
                            }
                        }

                        setTimeout(() => {
                            localStorage.setItem('checkedMenuId', tmpMenuId);
                            localStorage.setItem('jumpPath', _path);
                            this.$router.push({ path: _path });
                        }, 500);
                    }
                })
                .catch((res) => {
                    console.log(res);
                });
        }
    }
};
</script>
<style scoped>
* {
    user-select: none;
}
.login_box .submit_btn {
    width: 100%;
    background: #22bdbd;
    border-color: #22bdbd;
    color: #fff;
}
.login_box .el-button:focus,
.login_box .el-button:hover,
.code_btn:hover {
    background: #81eff0;
    border-color: #81eff0;
    color: #4c6161;
}
.verify-box {
    margin-left: 0.2rem;
    width: 86px;
}

.verify-box img {
    height: 40px;
}
.code_btn {
    width: 125px;
    margin-left: 15px;
    background: #22bdbd;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
    border: none;
}
.code_btn.active {
    color: #aaa;
    background: #eeeeee;
}
.qrcode_input {
    width: 190px;
}
.qrcode /deep/.el-form-item__content {
    display: flex;
    width: 100%;
}
.wrap {
    height: 100%;
    width: 100%;
    position: relative;
    background-image: url(../../public/images/msi/login_bg.png);
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: cover;
    background-position: center;
}
.version {
    position: absolute;
    bottom: 0.5rem;
    right: 20%;
    z-index: 50;
    color: #858688;
}
.login_box {
    /* width:4.2rem;
	    height:5.9rem; */
    width: 350px;
    /* height:320px; */
    position: absolute;
    top: 50%;
    right: 12%;
    transform: translate(-12%, -50%);
    z-index: 50;
    background: url(../../public/images/msi/login_form.png) no-repeat;
    background-size: 100% 100%;
    border-radius: 0.1rem;
    padding: 30px;
    /* color: #fff; */
}
.login_box .el-form-item__label {
    color: #fff !important;
}
.login_box .el-input__inner {
    background-color: rgba(255, 255, 255, 0.1);
    /* color: #fff; */
}
.login_title {
    font-size: 0.3rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #fff;
    margin-bottom: 40px;
    text-align: center;
}
.login_form {
    width: 100%;
    margin: 0 auto 0;
}
.login_form .el-input__inner {
    color: #cfdde5;
}
.login_btn button {
    border: none;
    background: #003b8f;
}
.login_btn button:hover {
    background: #01265a;
}
.user,
.password {
    width: 100%;
    /* height:0.48rem; */
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-bottom: 1px solid #dfe5ec;
    padding: 0 0.16rem;
}
.password {
    justify-content: space-between;
}
.user span,
.password span {
    width: 0.14rem;
}
.password_box {
    display: flex;
    align-items: center;
}

.isshowpass {
    cursor: pointer;
    width: 0.22rem;
}
.login_btn {
    margin-top: 30px;
}
.login_btn button {
    width: 100%;
}
.resetPassBtn {
    color: #409eff;
    cursor: pointer;
}
</style>